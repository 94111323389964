.list-benefits
  display: flex
  flex-wrap: wrap
  gap: 16px
  list-style: none
  padding-left: 0px !important
  justify-content: center !important
  

.item-benefits
   position: relative
   display: flex
   flex-direction: column
   width: calc(90%/2 - 12px)
   padding: 25px 52px 25px 95px
   background-color: $white !important
   border-radius: 16px

.wrapper-bnf
   position: relative
   z-index: 10

.title-bnf
   font-weight: 600
   font-size: 20px
   line-height: 30px
   color: rgba(3, 22, 25, 1)
   margin-bottom: 2px

.tab-wrapper 
   display: flex
   flex-direction: row  

.title-prof
   width: 580px
   font-weight: 600
   font-size: 24px
   line-height: 31px
   color: rgba(176, 177, 191, 1)
   background-color: transparent
   border: transparent
   border-bottom: 1px solid $lightblue !important
   text-align: center
   padding-bottom: 12px
   padding-top: 12px
   margin-bottom: 24px
   cursor: pointer

.title-us 
   position: absolute
   bottom: 200%
   left: 44%
   font-weight: 600
   font-size: 24px
   line-height: 31px
   color: rgba(176, 177, 191, 1)
   background-color: transparent
   border: transparent
   border-bottom: 1px solid $lightblue !important
   padding: 12px 189px
   margin-bottom: 24px
   margin-left: 65px
   &:active
     color: $white !important
     border: 1px solid $lightblue !important
     border-radius: 12px 12px 0px 0px

.img-bnf
  position: absolute
  left: 25px
  top: 30%

.text-bnf
   font-weight: 400
   font-size: 20px
   line-height: 26px
   color: rgba(84, 89, 99, 1)

.bnf-decor
    position: absolute
    z-index: 1
    bottom: -20px
    right: 40px