.services-section
   background-color: $lightblue !important
   padding: 80px 132px 80px 132px

.list-service
   display: flex
   flex-wrap: wrap
   gap: 24px
   list-style: none
   justify-content: space-around

.card-item
   width: 576px
   height: auto
   background-color: $white !important
   display: flex
   padding: 30px 
   border-radius: 16px

.img-serv
   width: 200px
   height: 200px
   margin-top: auto
   margin-bottom: auto

.title-service
   font-weight: 600
   font-size: 28px
   line-height: 42px
   color: $black !important
   margin-bottom: 16px

.text-serv
   font-weight: 400
   font-size: 20px
   line-height: 26px
   color: $grey !important
   margin-bottom: 32px