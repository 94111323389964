.works 
  background-color: #f3fcff
  padding: 80px 132px


.work_cards 
  display: flex
  gap: 24px
  justify-content: center
  flex-wrap: wrap

.card-text 
  padding: 36px 24px 0px 24px
  position: relative
  
.card_photo 
  overflow: hidden
  width: 376px
  height: 376px
  border-radius: 16px
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  

.img_card_photo 
  display: block
  width: auto
  height: auto
  margin-left: -6px
  margin-top: -2px

.text-work 
  display: flex
  flex-direction: column
  padding: 36px 24px 0 24px
  gap: 18px
  width: 376px
  height: 100%
  text-align: left
  background-image: url(../../assets/img/works/1.svg)

.text-work.secondary
  background-image: url(../../assets/img/works/2.svg)

.text-work.third
  background-image: url(../../assets/img/works/3.svg)

.text-work.fourth
  background-image: url(../../assets/img/works/4.svg)

.text-work.fifth
  background-image: url(../../assets/img/works/5.svg)

.title-work 
  font-weight: 600
  font-size: 24px
  line-height: 31px
  color: #031619

.text-cont 
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: #545963
