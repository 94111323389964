.stories
   display: flex
   gap: 670px
   align-item: center
   margin-bottom: 25px
   height: 63px

.blog-thumb
   display: flex
   flex-direction: column
   background-color: $white !important
   border-radius: 16px 
   width: 375px
   height: auto

.btn-blog
   display: flex
   flex-wrap: wrap
   gap: 10px
   margin-bottom: 45px

.avatar
   width: 36px
   height: 36px
   border-radius: 18px
   margin-right: 8px

.blog-name
   font-weight: 600
   font-size: 20px
   line-height: 28px
   color: rgba(3, 22, 25, 1)
   margin: 0 !important

.blog-time
   font-weight: 400
   font-size: 14px
   line-height: 21px
   color: rgba(84, 89, 99, 1)
   margin: 0 !important
