.teams_cards 
  width: 1220px
  height: auto
  gap: 65px
  display: flex
  flex-wrap: wrap
  z-index: 10

.team_card
  display: flex
  flex-direction: column
  justify-content: center
  width: 245px
  height: auto
  background-color: #fbfbfb
  border-radius: 16px
  z-index: 10

.team-img
   display: flex
   justify-content: center
   align-items: center
   padding: 16px 22px

.team-ass-img
    border-radius: 50%
    
.team-content
   display: flex
   flex-direction: column
   gap: 10px 
   margin-top: 36px
   margin-bottom: 16px
   h4
     font-weight: 600
     font-size: 24px
     line-height: 31px
     color: $black !important
     text-align: center
   p
     font-weight: 400
     font-size: 16px
     line-height: 24px
     color: $grey !important
     text-align: center

