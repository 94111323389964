.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 44px
  padding: 0 30px
  color: $darkblue !important
  font-size: 14px
  font-weight: 600
  line-height: 22px
  border-radius: 8px
  transition: all ease-out .25s
  background-color: $white 

   
  +v-sm
    display: flex
    width: 60%
    margin: 24px auto
  +v-xs
    display: flex
    width: 100%
    margin: 24px 0
    padding: 0
    font-size: 0.82em
#top
  .button
    +v-sm
      display: inline-flex
      width: 40%
      margin: 0 24px 0 0
    +v-xs
      display: flex
      width: 100%
      margin: 24px 0

.btn-header
    background-color: $blue !important
    color: $white !important
    &:hover
      background-color: #fff !important
      border: 1px solid #149afa !important
      color: #0050C7 !important
      
.icon-wrapper
   border: transparent
   background-color: $lightgrey
   margin-top: 10px
   margin-right: 24px

.btn-hero
    background-color: $blue !important
    border: 1px solid #149afa !important
    color: $white !important
    font-size: 14px
    font-weight: 600
    line-height: 22px
    padding: 11px 16px 11px 24px
    width: 126px
    &.serv
      background-color: #fff !important
      border: 1px solid #149afa !important
      color: $blue400 !important
      width: 170px
    &.cards
       width: 151px

.btn-hero::after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-white.svg')
    background-repeat: no-repeat
    margin-left: 8px

.serv::after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-blue.svg')
    background-repeat: no-repeat
    margin-left: 8px

.card::after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-blue.svg')
    background-repeat: no-repeat
    margin-left: 8px

.btn-hero:hover
    background-color: #fff !important
    border: 1px solid #149afa !important
    color: $blue400 !important

.btn-hero:hover:after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-blue.svg')
    background-repeat: no-repeat
    margin-left: 8px 

.serv:hover
    background-color: $blue !important
    border: 1px solid #149afa !important
    color: $white !important
    &:after
      background-image: url('../../assets/img/svg_ass/to-right-white.svg')
   

.arrow-up
  position: fixed
  right: 30px
  bottom: 30px
  width: 48px
  height: 48px
  margin: 0
  padding: 0
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%
  background-color: rgba(20, 154, 250, 1)
  cursor: pointer

.button-services
  display: inline-flex
  justify-content: center
  align-items: center
  height: 44px
  padding: 0 30px
  color: $white !important
  font-size: 14px
  font-weight: 500
  line-height: 22px
  border-radius: 8px
  transition: all ease-out .25s
  background-color: $blue 

  .btn-services
    background-color: $blue !important
    border: 1px solid $blue !important
    color: $white !important
    font-size: 14px
    font-weight: 500
    line-height: 22px
    padding: 11px 16px 11px 24px
    width: 151px

.btn-services::after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-white.svg')
    background-repeat: no-repeat
    margin-left: 8px

.btn-services:hover
    background-color: #fff !important
    border: 1px solid $blue !important
    color: $blue !important

.btn-services:hover:after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-blue.svg')
    background-repeat: no-repeat
    margin-left: 8px 