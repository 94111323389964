.cases
  background-color: $darkblue !important
  padding: 80px 132px 80px 132px
     

.studies
   display: flex
   gap: 800px
   align-item: center
   margin-bottom: 25px
   height: 63px
      
.title-accent
   display: block
   font-weight: 500
   font-size: 24px
   line-height: 36px
   color: rgba(176, 177, 191, 1)
   margin-bottom: 60px
   width: 95% 
   &.pro
      color: $grey !important
      margin-left: 70px
      
.card-thumb
   display: flex
   flex-direction: column
   background-color: $white !important
   border-radius: 16px 
   width: 275px
   height: auto

.case-img
   display: block
   width: 100%
   height: 200px
   border-radius: 16px 16px 0px 0px
   overflow: hidden
   margin-top: -2px
   
.content-case
   padding: 16px 20px


.title-case
   font-weight: 600
   font-size: 24px
   line-height: 31px
   color: rgba(3, 22, 25, 1)
   margin-bottom: 16px

.text-case
   font-weight: 400
   font-size: 16px
   line-height: 24px
   color: rgba(84, 89, 99, 1)
   margin-bottom: 18px
   
.btn-group
   display: flex
   flex-wrap: wrap
   gap: 10px
   margin-bottom: 45px

.btn-cases
   position: relative
   width: 157px
   height: 56px
   border-radius: 8px transparent
   background-color: transparent
   color: $white !important
   &:hover
     color: $blue400 !important

.btn-cases::after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-white.svg')
    background-repeat: no-repeat
    margin-left: 8px
    

.btn-cases:hover:after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-blue.svg')
    background-repeat: no-repeat
    margin-left: 8px 

.btn-case
   height: 30px
   border-radius: 12px 
   border-color: rgba(20, 154, 250, 1)
   background-color: transparent
   font-weight: 600
   font-size: 10px
   line-height: 14px
   color: rgba(22, 38, 65, 1)

.btn-exp
   display: block
   width: 127px
   height: 44px
   padding: 10px 24px
   background-color: transparent
   border-radius: 8px transparent
   color: $blue !important
   margin: 0 auto
   text-align: center
