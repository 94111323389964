.bg-light-custom 
  background-color: #162641
  display: flex
  flex-direction: column
  padding-top: 40px
  
.navigation
  display: flex
  gap: 48px 
  color: #fff

.footer-link
  font-weight: 600
  font-size: 16px
  line-height: 19px
  transition: color 0.3s ease
  color: white
  &:hover 
      color: $blue400 !important

.footer-contact_link
   color: white
   &:hover 
      color: $blue400 !important


.contact 
  width: 206px
  color: #b0b1bf
  margin-bottom: 40px
  &-title
    font-weight: 600
    font-size: 16px
    line-height: 19px
    transition: color 0.3s ease
    color: white
    margin-bottom: 18px
    &:hover 
      color: $blue400 !important
  &-icon
      width: 16px
      height: 16px
      margin-right: 6px
  &-link
     font-weight: 400
     font-size: 16px
     line-height: 24px
     margin-bottom: 12px
     color: #B0B1BF
     
.container2 
  background-color: #0050c7
  width: 100%
  padding-top: 14px
  padding-bottom: 14px
  display: flex
  justify-content: space-around
  color: #fff
  font-weight: 400
  font-size: 14px
  line-height: 21px

.footer-privacy
  display: flex
  gap: 48px
  

