.mission
  margin-top: 130px
  padding-top: 200px
  padding-bottom: 120px
  background-color: $darkblue !important

.mission-vision
   display: flex
   gap: 120px
   justify-content: center

.title-ass
   position: relative
   margin-left: 70px
   margin-bottom: 60px
   color: $white !important
   font-weight: 600
   font-size: 36px
   line-height: 54px
   &.service
     color: $black !important
     margin-left: 70px !important
     margin-bottom: 25px !important


.title-ass::before
   content: ""
   display: inline-block
   position: absolute
   top: 60px
   width: 100px
   height: 3px
   border-radius: 1px
   background-color: $blue !important

.about-mission
   list-style: none

.item-mission
   position: relative
   display: flex
   flex-direction: column


.mission-title
   color: $white !important
   font-family: $basic
   font-weight: 600
   font-size: 28px
   line-height: 42px


.mission-text
   display: block
   width: 528px
   height: 210px
   color: $white !important
   font-weight: 400
   font-size: 20px
   line-height: 26px

.mission-img
   width: 528px
   height: 454px
   border-radius: 12px
   position: relative
   z-index: 10
 

.mission-decor
   position: absolute
   z-index: 1
   bottom: -30px
   left: -30px
 
.mission-decor-v 
   position: absolute
   z-index: 1 
   top: -30px
   right: -30px

.mission-decor-t
   position: absolute
   z-index: 1 
   top: 140px
   right: 30px

.mission-decor-m
   position: absolute
   z-index: 1
   bottom: -20px
   left: 40px