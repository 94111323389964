// Colors
.c
  &-white
    color: $white !important
  &-dark
    color: $dark !important
  &-blue
    color: $blue !important
  &-pink
    color: $pink !important
  &-violet
    color: $violet !important
  &-marine
    color: $marine !important
  &-theme
    color: $theme !important
  &-darkblue
    color: $darkblue !important
  &-black
     color: $black !important
  &-darckgrey
     color: $darckgrey !important
  &-grey
     color: $grey !important
  &-lightblue
     color: $lightblue !important
     
.b-none
  border: none !important
  


// Background Colors
.bg
  &-body
    background-color: $body !important
  &-white
    background-color: $white !important
  &-dark
    background-color: $dark !important
  &-blue
    background-color: $blue !important
  &-marine
    background-color: $marine !important
  &-light
    background-color: $light !important
  &-theme
    background-color: $theme !important
  &-grey
    background-color: $grey !important
  &-lightgrey
    background-color: $lightgrey !important
  &-darkblue
    background-color: $darkblue !important
  &-lightblue
    background-color: $lightblue !important
