*
  font-family: $basic

.ma
  margin-left: auto !important
  margin-right: auto !important
.mla
  margin-left: auto !important
.mra
  margin-right: auto !important
.mb
  margin-bottom: 20px !important
  &-24
     margin-bottom: 24px
.mbh
   margin-top: 60px !important
.mbt
   margin-top: 30px !important
.mbv
   margin-bottom: 48px !important
.mrc
   margin-right: 100px !important
.mnl
   margin-left: 0px !important
.mbb
   margin-bottom: 25px !important
   margin-right: auto !important
.mis
   margin-left: 70px !important
.mgh
   margin: 0px !important
.mwr
   matgin-left: 38px !important
.pa
   padding: 0px !important


.obfc
  object-fit: cover

.w-100
  width: 100% !important
  width: 100% !important
  min-width: 100% !important
  max-width: 100% !important
.w-90
  width: 90% !important
  min-width: 90% !important
  max-width: 90% !important
.w-80
  width: 80% !important
  min-width: 80% !important
  max-width: 80% !important
.w-70
  width: 70% !important
  min-width: 70% !important
  max-width: 70% !important
.w-60
  width: 60% !important
  min-width: 60% !important
  max-width: 60% !important
.w-50
  width: 50% !important
  min-width: 50% !important
  max-width: 50% !important
.w-40
  width: 40% !important
  min-width: 40% !important
  max-width: 40% !important
.w-30
  width: 30% !important
  min-width: 30% !important
  max-width: 30% !important
.w-20
  width: 20% !important
  min-width: 20% !important
  max-width: 20% !important
.w-15
  width: 15% !important
  min-width: 15% !important
  max-width: 15% !important
.w-10
  width: 10% !important
  min-width: 10% !important
  max-width: 10% !important
.w-3
  width: 33.33333% !important
  min-width: 33.33333% !important
  max-width: 33.33333% !important
.w-4
  width: 25% !important
  min-width: 25% !important
  max-width: 25% !important

.w-100,
.w-90,
.w-80,
.w-70,
.w-60,
.w-50,
.w-40,
.w-30,
.w-20,
.w-10,
.w-3,
.w-4
  +v-sm
    width: 96% !important
    margin-left: auto
    margin-right: auto

.wide
  +v-sm
    min-width: 90% !important
    margin-left: auto
    margin-right: auto

.s-10,
.s-20,
.s-33,
.s-35,
.s-40,
.s-50,
.s-60,
.s-70,
.s-80,
.s-90,
.s-100
  width: 100% !important
  clear: both
  display: block
  +v-sm
    height: 10px !important
    min-height: 10px !important
    max-height: 10px !important

.s-0
  width: 100% !important
  clear: both
  display: block
  overflow: hidden
  height: 0 !important

.s
  &-10
    height: 10px
  &-20
    height: 20px
  &-30
    height: 30px
  &-33
    height: 33px
  &-40
    height: 40px
  &-50
    height: 50px
  &-60
    height: 60px
  &-70
    height: 70px
  &-80
    height: 80px
  &-90
    height: 90px
  &-100
    height: 100px

.aic
  align-items: center !important

.aifs
  align-items: flex-start !important

.aife
  align-items: flex-end !important
.aifh
  align-items: stretch !important
.jcc
  justify-content: center !important

.jcfs
  justify-content: flex-start !important

.jcfe
  justify-content: flex-end !important

.jcsa
  justify-content: space-around !important

.jcsb
  justify-content: space-between !important

.jsbn
  justify-content: normal !important

.center
  justify-content: center !important

.tl
  text-align: left !important

.tr
  text-align: right !important

.tc
  text-align: center !important

.r
  &-16
    border-radius: 16px !important
  &-32
    border-radius: 32px !important
  &-24
    border-radius: 24px !important
  &-8
    border-radius: 8px !important
  &-10
    border-radius: 10px !important
  &-4
    border-radius: 4px !important

.rowrap
  flex-flow: row wrap

.fgrow
  flex-grow: 1

.col
  flex-direction: column

.oh
  overflow: hidden

.flex
  display: flex

.block
  display: block

.h
  &-100
    height: 100vh !important
    min-height: 100vh !important
    +v-sm
      height: auto
      min-height: auto !important
      padding-top: 20%
  &-90
    height: 90vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-80
    height: 80vh
    min-height: 80vh !important
    +v-sm
      height: auto
      min-height: auto !important
      padding-top: 2%
      padding-bottom: 2%
  &-70
    height: 70vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-60
    height: 60vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-55
    height: 55vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-50
    height: 50vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-45
    height: 45vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-40
    height: 40vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-30
    height: 30vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-20
    height: 20vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%
  &-10
    height: 10vh
    +v-sm
      height: auto
      padding-top: 2%
      padding-bottom: 2%

.upp
  text-transform: uppercase

.ex
  overflow: hidden
  height: 116px
  display: -webkit-box
  -webkit-line-clamp: 6
  -webkit-box-orient: vertical
  text-overflow: ellipsis
  +v-md
    height: 300px !important
    text-overflow: none
    min-height: 100%
    padding-bottom: 0 !important
  +v-sm
    height: 200px !important
    text-overflow: none
    min-height: 50%
    padding-bottom: 0 !important
  +v-xs
    height: 320px !important
    text-overflow: none
    min-height: 100%
    padding-bottom: 0 !important
  &:hover
    overflow: visible
    height: auto
    text-overflow: none
    height: 300px

.lh
  &-1
    line-height: 1 !important
  &-105
    line-height: 1.05 !important
  &-125
    line-height: 1.25 !important
  &-15
    line-height: 1.5 !important
  &-175
    line-height: 1.75 !important
  &-2
    line-height: 2 !important

.px
 &-50
   padding: 0 50px
   +v-sm
       padding: 0 20px
   +v-xs
       padding: 0 10px

.sh
  filter: drop-shadow(0px 4px 6px 0px red)
  
         
.pr
  position: relative

.gp 
  gap: 16px
  &-24
    gap: 24px
  &-4
    gap: 4px