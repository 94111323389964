$pr1: #C7E1ED
$pr2: #f9ddf9
$pr3: #F1E5AA
$pr4: #E3EFB7
$pr5: #B2F0E5
$body: #fff
$theme: #f47b1e
$dark: #363636
$white: #fff
$light: #F5F5F5
$medium: #505050

$button: $theme
$intro: $dark

$blue: #149AFA
$violet: #8E4A95
$darkblue: #162641
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B


// Associo
$body: #FFFFFF
$lightgrey: #FBFBFB
$grey: #545963
$darckgrey: #9597AA
$black: #031619
$lightblue: #F3FCFF
$blue: #149AFA
$darkblue: #162641
$blue400: #0050C7


$basic: sans-serif
$inter: $basic

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
