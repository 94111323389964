.outline
   outline: dashed red

.form-sect
   padding: 80px 132px

.base-form
   display: flex
   flex-direction: column
   justify-content: center
   align-items: center

.title-form
   position: relative
   font-weight: 600
   font-size: 36px
   line-height: 54px
   color: $black !important
   margin-bottom: 33px

.title-form::before
   content: ""
   display: inline-block
   position: absolute
   top: 60px
   width: 100%
   height: 3px
   border-radius: 1px
   background-color: $blue !important
  
.form-text
   font-weight: 500
   font-size: 24px
   line-height: 36px
   color: $grey !important
   margin-bottom: 60px

.mail-form
   display: flex
   gap: 65px
   position: relative
   justify-content: center
   align-items: center

.mail-input
   display: block !important
   border: transparent
   width: 390px

.thumb-contact
   display: flex
   justify-content: space-between
   
.title-contact
   position: relative
   font-weight: 600
   font-size: 36px
   line-height: 54px
   color: $black !important

.title-contact::before
   content: ""
   display: inline-block
   position: absolute
   top: 60px
   width: 100px
   height: 3px
   border-radius: 1px
   background-color: $blue !important

.contact-text
   display: block
   position: relative
   font-weight: 400
   font-size: 12px
   line-height: 20px
   color: $grey !important
   margin: 0 !important

.contact-grup
   display: flex
   flex-direction: column
   gap: 48px
   

contact-label
   margin-bottom: 24px

.contact-input
   display: block !important
   border: transparent
   border-bottom: 1px solid rgba(0, 0, 0, 0.42)
   border-radius: 4px
   color: rgba(176, 177, 191, 1)
   width: 100%
   height: 58px
   transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1)
   &.comment
     resize: none
     height: 100%

.contact-form
   position: relative
   display: flex
   flex-direction: column
   gap: 4px

.btn-submit
    background-color: $blue !important
    border: 1px solid #149afa !important
    color: $white !important
    font-size: 14px
    font-weight: 600
    line-height: 22px
    padding: 10px 24px 10px 24px
    width: 111px
        
.btn-submit::after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-white.svg')
    background-repeat: no-repeat
    margin-left: 8px
    margin-top: 3px

.btn-submit:hover
    background-color: #fff !important
    border: 1px solid #149afa !important
    color: $blue400 !important

.btn-submit:hover:after
    content: ' '
    width: 24px
    height:24px
    background-size: contain
    background-image: url('../../assets/img/svg_ass/to-right-blue.svg')
    background-repeat: no-repeat
    margin-left: 8px 